import mapboxgl from "mapbox-gl";
import "dotenv/config";

mapboxgl.accessToken = process.env.MAPBOX_TOKEN;

const maps = document.querySelectorAll(".karte");
if (maps) {
	Array.from(maps).forEach((elem) => {
		const coords = elem.dataset.coords.split(";");
		const center = elem.dataset.center.split(",");
		const zoom = elem.dataset.zoom || 13;

		const map = new mapboxgl.Map({
			container: elem,
			style: "mapbox://styles/uhorn/cl0thz275002215s9z2mgy1ws", // style URL
			center: center, // starting position [lng, lat]
			zoom: zoom, // starting zoom
		});
		map.scrollZoom.disable();

		coords.forEach((coord) => {
			const el = document.createElement("div");
			el.className = "marker";
			el.innerHTML = "<span></span>";
			const marker = new mapboxgl.Marker(el);

			console.log({ coord });
			marker.setLngLat(coord.split(",")).addTo(map);
		});
	});
}
